import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import './LoadingPage.css'; // Ensure this points to the correct CSS file

// Define the same secret key used for encryption
const SECRET_KEY = 'your-secret-key'; // Must be the same as the one used for encryption

const decrypt = (cipherText) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption failed:", error);
    return null; // Return null if decryption fails
  }
};

function LoadingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encryptedUrl = queryParams.get('url'); // Extract the encrypted 'url' parameter from the query string

  useEffect(() => {
    if (encryptedUrl) {
      const url = decrypt(encryptedUrl);
      if (url) {
        console.log("Redirecting to:", url);
        // Redirect to the Google Drive file URL after 5 seconds
        const timer = setTimeout(() => {
          window.location.href = url;
        }, 5000);

        // Clean up the timer if the component unmounts before redirect
        return () => clearTimeout(timer);
      } else {
        console.error("Failed to decrypt URL. Navigating to home.");
        navigate('/'); // Navigate to the homepage if decryption fails
      }
    } else {
      console.log("URL is not available, navigating back to home.");
      navigate('/'); // Navigate to the homepage if no URL is provided
    }
  }, [encryptedUrl, navigate]);

  // Generate an obfuscated link to the Google Drive file
  const generateProtectedLink = (url) => {
    return `data:text/plain;base64,${btoa(url)}`; // Encode the URL in Base64 for obfuscation
  };

  const protectedLink = encryptedUrl ? generateProtectedLink(encryptedUrl) : '#';

  return (
    <div className="loader-container">
      <div className="loader"></div>
      <h2>Loading...</h2>
      <p>
        Please wait while we redirect you to the file. If you are not redirected automatically,
        <a href={protectedLink} target="_blank" rel="noopener noreferrer" className="link-protected">
          click here
        </a>.
      </p>
    </div>
  );
}

export default LoadingPage;
